<template>
  <div>
    <search @change="onChange"></search>
    <div class="list">
      <div v-infinite-scroll="onReachBottom" infinite-scroll-disabled="loading" infinite-scroll-distance="10">
        <div class="listCt" v-for="item in list" :key="item.id">
          <div class="title">{{ item.title }}报修</div>
          <div>
            <p>报修人：{{ item.contacts }}</p>
            <p>报修电话：{{ item.contactsMobile }}</p>
            <p>报修位置：{{ item.position }}</p>
            <p class="super_long">详情描述：{{ item.details }}</p>
          </div>
          <div class="time">
            <p>报修时间：{{ item.createTime }}</p>
            <p>维修状态：{{ item.repairStatus_dictText }}</p>
          </div>
          <div class="confirm-buts">
            <div @click="detail(item)" class="confirm-but">查看</div>
            <div @click="update(item)" class="confirm-but" v-if="item.repairStatus == 0">编辑</div>
            <div @click="remove(item)" class="confirm-but del_but" v-if="item.repairStatus == 0">删除</div>
          </div>
        </div>
      </div>
      <div v-if="!loading" class="nodata">已无更多数据</div>
      <div v-if="!isEnd && loading" class="loading">
        <mt-spinner color="#178CF9" type="snake"></mt-spinner>
      </div>
      <div class="footer"></div>
      <div class="add_but" @click="addNewRepair">设备报修</div>
    </div>
  </div>
</template>

<script>
import { listRepairData, delRepairData } from '@/api/enterprise'
import { MessageBox, Toast } from 'mint-ui'
import Search from './components/Search'

export default {
  components: { Search },
  data() {
    return {
      loading: false,
      pageNo: 0,
      isEnd: false,
      list: [],
      options: {}
    }
  },
  mounted() {
    this.refresh()
  },
  methods: {
    onChange(json) {
      this.options = json
      this.refresh()
    },
    // 背景图列表
    refresh() {
      this.isEnd = false
      this.list = []
      this.loadData(1)
    },
    async loadData(pageNo) {
      this.loading = true
      try {
        const { success, result } = await listRepairData({
          pageSize: 10,
          pageNo,
          ...this.options
        })
        const totalPage = result.pages
        if (success) {
          if (+pageNo === 1) {
            this.list = result.records
          } else {
            this.list = this.list.concat(result.records)
          }
          this.pageNo = pageNo
          if (this.pageNo >= totalPage) {
            this.isEnd = true
          }
        }
      } catch (error) {
        this.isEnd = true
      } finally {
        this.loading = false
      }
    },
    onReachBottom() {
      if (this.isEnd) return
      this.loadData(this.pageNo + 1)
    },
    // 去往新增
    addNewRepair() {
      this.$router.push({
        name: 'repairAdd',
        query: {}
      })
    },
    detail(item) {
      this.$router.push({
        name: 'repairDetail',
        query: { id: item.id }
      })
    },
    update(item) {
      this.$router.push({
        name: 'repairEdit',
        query: { id: item.id }
      })
    },
    remove(item) {
      const that = this
      MessageBox.confirm('', {
        title: '提示',
        message: '确认删除此报修单吗？',
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      })
        .then((action) => {
          delRepairData({ id: item.id }).then((res) => {
            Toast(res.message)
            that.refresh()
          })
        })
        .catch((error) => {
          console.log('取消删除' + error)
        })
    }
  }
}
</script>

<style lang="less" scoped>
.list {
  min-height: 100vh;
  padding: 10px 15px;
  background: #f7f8fc;
  margin-top: 44px;

  .listCt {
    background: #ffffff;
    padding: 0 15px;
    margin-bottom: 10px;
    font-weight: 'Source Han Sans CN';

    .title {
      font-weight: 500;
      font-size: 15px;
      border-bottom: solid 1px #e8e8e8;
      line-height: 50px;
    }

    .time {
      //border-top: solid 1px #e8e8e8;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 40px;
    }

    .confirm-buts {
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #e8e8e8;

      .confirm-but {
        width: 100%;
        font-weight: 500;
        font-size: 15px;
        text-align: center;
        padding: 15px;
        border-left: 1px solid #e9edf2;
        color: #1073e3;
      }

      .del_but {
        color: red;
      }

      .confirm-but:first-child {
        border-left: none;
      }
    }

    .super_long {
      display: -webkit-box;
      -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
      overflow: hidden;
      white-space: normal;
      text-overflow: ellipsis;
      word-wrap: break-word;
      -webkit-line-clamp: 3;
    }
  }

  .load-all {
    /* 数据加载完毕提示信息 */
    padding-bottom: 15px;
    font-size: 14px;
    text-align: center;
    color: #c8c8cf;
  }

  .add_but {
    position: fixed;
    bottom: 30px;
    width: 335px;
    height: 44px;
    border-radius: 5px 5px 5px 5px;
    background: rgba(16, 115, 227, 1);
    color: #ffffff;
    font-weight: 500;
    font-size: 17px;
    font-weight: 'Source Han Sans CN';
    text-align: center;
    line-height: 44px;
  }
}

.loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.nodata {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #666666;
}

.footer {
  height: 80px;
}
</style>
