import { axios } from '@/utils/request'
import { getCookie } from '@/utils/domUtil'
import { ACCESS_TOKEN } from '@/store/mutation-types'

/**
 * 企业服务相关接口
 *
 *
 *
 */

// 报修列表
export function listRepairData(parameter) {
  return axios({
    url: '/repair/tbRepair/list',
    method: 'get',
    params: parameter
  })
}

export function upload(fileData) {
  const token = getCookie(ACCESS_TOKEN)
  return axios({
    headers: { 'X-Access-Token': token },
    method: 'post',
    url: '/sys/common/upload',
    data: fileData
  })
}

// 添加
export function addRepairData(data) {
  const token = getCookie(ACCESS_TOKEN)
  return axios({
    headers: { 'X-Access-Token': token },
    url: '/repair/tbRepair/add',
    method: 'post',
    data
  })
}

export function getRepairData(parameter) {
  const token = getCookie(ACCESS_TOKEN)
  return axios({
    headers: { 'X-Access-Token': token },
    url: '/repair/tbRepair/queryById',
    method: 'get',
    params: parameter
  })
}

// 修改
export function editRepairData(data) {
  const token = getCookie(ACCESS_TOKEN)
  return axios({
    headers: { 'X-Access-Token': token },
    url: '/repair/tbRepair/edit',
    method: 'post',
    data
  })
}

// 确认完工
export function editRepairAudit(data) {
  const token = getCookie(ACCESS_TOKEN)
  return axios({
    headers: { 'X-Access-Token': token },
    url: '/repair/tbRepair/audit',
    method: 'post',
    data
  })
}

export function delRepairData(parameter) {
  const token = getCookie(ACCESS_TOKEN)
  return axios({
    headers: { 'X-Access-Token': token },
    url: '/repair/tbRepair/delete',
    method: 'delete',
    params: parameter
  })
}

// 获取需求类型
export function listDictData(params) {
  return axios({
    url: '/sys/api/queryEnableDictItemsByCode',
    method: 'get',
    params
  })
}

// 需求列表
export function listProjectData(parameter) {
  return axios({
    url: '/org/tbProject/public/projects',
    method: 'get',
    params: parameter
  })
}

// 费用查询
export function listCostData(parameter) {
  return axios({
    url: '/property/tbProperty/list',
    method: 'get',
    params: parameter
  })
}

// 新费用
export function queryPageListOrgWx(parameter) {
  return axios({
    url: '/property/tbProperty/queryPageListOrgWx',
    method: 'get',
    params: parameter
  })
}

// 查看详情
export function getDetail(projectId, params) {
  const token = getCookie(ACCESS_TOKEN)
  return axios({
    headers: { 'X-Access-Token': token },
    url: '/org/tbProject/findProjectDetails/' + projectId,
    method: 'get',
    params
  })
}

// 查看详情
export function getDetailForPublic(projectId, params) {
  return axios({
    url: '/org/tbProject/public/projects/' + projectId,
    method: 'get',
    params
  })
}

// 抢单
export function toOffer(data) {
  return axios({
    url: '/operation/tbProjectOffer/projects/actions/offer',
    method: 'post',
    data
  })
}

// 历史反馈查询
export function listFeedbackData(parameter) {
  return axios({
    url: '/feedback/tbFeedback/list',
    method: 'get',
    params: parameter
  })
}

// 新增反馈信息
export function addFeedback(data) {
  return axios({
    url: '/feedback/tbFeedback/add',
    method: 'post',
    data
  })
}

// 新增反馈信息
export function editFeedback(data) {
  return axios({
    url: '/feedback/tbFeedback/edit',
    method: 'post',
    data
  })
}

// 查看详情
export function getFeedbackDetail(params) {
  const token = getCookie(ACCESS_TOKEN)
  return axios({
    headers: { 'X-Access-Token': token },
    url: '/feedback/tbFeedback/queryById/',
    method: 'get',
    params
  })
}

// 删除反馈信息
export function delFeedbackData(parameter) {
  const token = getCookie(ACCESS_TOKEN)
  return axios({
    headers: { 'X-Access-Token': token },
    url: '/feedback/tbFeedback/delete',
    method: 'delete',
    params: parameter
  })
}
