var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("search", { on: { change: _vm.onChange } }),
      _c("div", { staticClass: "list" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "infinite-scroll",
                rawName: "v-infinite-scroll",
                value: _vm.onReachBottom,
                expression: "onReachBottom"
              }
            ],
            attrs: {
              "infinite-scroll-disabled": "loading",
              "infinite-scroll-distance": "10"
            }
          },
          _vm._l(_vm.list, function(item) {
            return _c("div", { key: item.id, staticClass: "listCt" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(item.title) + "报修")
              ]),
              _c("div", [
                _c("p", [_vm._v("报修人：" + _vm._s(item.contacts))]),
                _c("p", [_vm._v("报修电话：" + _vm._s(item.contactsMobile))]),
                _c("p", [_vm._v("报修位置：" + _vm._s(item.position))]),
                _c("p", { staticClass: "super_long" }, [
                  _vm._v("详情描述：" + _vm._s(item.details))
                ])
              ]),
              _c("div", { staticClass: "time" }, [
                _c("p", [_vm._v("报修时间：" + _vm._s(item.createTime))]),
                _c("p", [
                  _vm._v("维修状态：" + _vm._s(item.repairStatus_dictText))
                ])
              ]),
              _c("div", { staticClass: "confirm-buts" }, [
                _c(
                  "div",
                  {
                    staticClass: "confirm-but",
                    on: {
                      click: function($event) {
                        return _vm.detail(item)
                      }
                    }
                  },
                  [_vm._v("查看")]
                ),
                item.repairStatus == 0
                  ? _c(
                      "div",
                      {
                        staticClass: "confirm-but",
                        on: {
                          click: function($event) {
                            return _vm.update(item)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    )
                  : _vm._e(),
                item.repairStatus == 0
                  ? _c(
                      "div",
                      {
                        staticClass: "confirm-but del_but",
                        on: {
                          click: function($event) {
                            return _vm.remove(item)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  : _vm._e()
              ])
            ])
          }),
          0
        ),
        !_vm.loading
          ? _c("div", { staticClass: "nodata" }, [_vm._v("已无更多数据")])
          : _vm._e(),
        !_vm.isEnd && _vm.loading
          ? _c(
              "div",
              { staticClass: "loading" },
              [
                _c("mt-spinner", { attrs: { color: "#178CF9", type: "snake" } })
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "footer" }),
        _c("div", { staticClass: "add_but", on: { click: _vm.addNewRepair } }, [
          _vm._v("设备报修")
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }